<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.reports">
			<template #title>Reports</template>
			<v-row>
				<v-col cols="12" md="4" xl="3" v-for="report in list">
					<ti-card :icon="$icons.report" icon-small>
						<v-card-title class="text-h4">{{ report.title }}</v-card-title>
						<v-card-subtitle v-if="report.subtitle">{{ report.subtitle }}</v-card-subtitle>
						<template #footer-actions>
							<v-row justify="end">
								<v-col cols="auto">
									<v-btn color="primary" :to="report.to">Run</v-btn>
								</v-col>
							</v-row>
						</template>
					</ti-card>
				</v-col>
			</v-row>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";

export default {
	name: "ReportsIndex",
	data: () => ({
		list: [
			{
				title: 'Benchmark Results',
				subtitle: 'Aggregated benchmark data',
				to: {name: 'ReportsBenchmarkResults'},
			},
			{
				title: 'Benchmark Results by User',
				subtitle: 'Benchmark data by user',
				to: {name: 'ReportsBenchmarkUserResults'},
			},
			{
				title: 'Benchmark Self Assessments',
				subtitle: 'User benchmark self assessment',
				to: {name: 'ReportsSelfAssessments'},
			},
			{
				title: 'User Activity',
				subtitle: 'User activity (e.g. courses completed)',
				to: {name: 'ReportsUserActivity'},
			},
			{
				title: 'Certificate Status',
				subtitle: 'User certificate status',
				to: {name: 'ReportsCertificationProgress'},
			},
			{
				title: 'Benchmark Comparison',
				subtitle: 'compare benchmark results',
				to: {name: 'ReportsBenchmarkComparison'},
			},
			{
				title: 'Benchmark Comparison Combined',
				subtitle: 'compare benchmark results with combined data',
				to: {name: 'ReportsBenchmarkComparisonCombined'},
			},
		]
	}),
}
</script>